* {
  box-sizing: border-box;
}



:root {
  --dark100: rgb(59, 59, 59);
  /* Lighter gray */
  --dark200: rgb(50, 50, 50);
  /* Slightly darker */
  --dark300: rgb(41, 41, 41);
  --dark400: rgb(32, 32, 32);
  --dark500: rgb(23, 23, 23);
  /* Midway, darker gray */
  --dark600: rgb(20, 20, 20);
  --dark700: rgb(17, 17, 17);
  --dark800: rgb(15, 15, 15);
  --dark900: rgb(13, 13, 13);
  /* Almost black */
  --white: #e6e1e3;
  --acc: #E56909;

  /* Red */
  --red100: #FFCDD2;
  --red200: #EF9A9A;

  /* Pink */
  --pink100: #F8BBD0;
  --pink200: #F48FB1;

  /* Purple */
  --purple100: #E1BEE7;
  --purple200: #CE93D8;

  /* Deep Purple */
  --deepPurple100: #D1C4E9;
  --deepPurple200: #B39DDB;

  /* Indigo */
  --indigo100: #C5CAE9;
  --indigo200: #9FA8DA;

  /* Blue */
  --blue100: #BBDEFB;
  --blue200: #90CAF9;

  /* Light Blue */
  --lightBlue100: #B3E5FC;
  --lightBlue200: #81D4FA;

  /* Cyan */
  --cyan100: #B2EBF2;
  --cyan200: #80DEEA;

  /* Teal */
  --teal100: #B2DFDB;
  --teal200: #80CBC4;

  /* Green */
  --green100: #C8E6C9;
  --green200: #A5D6A7;

  /* Light Green */
  --lightGreen100: #DCEDC8;
  --lightGreen200: #C5E1A5;

  /* Lime */
  --lime100: #F0F4C3;
  --lime200: #E6EE9C;

  /* Yellow */
  --yellow100: #FFF9C4;
  --yellow200: #FFF59D;

  /* Amber */
  --amber100: #FFECB3;
  --amber200: #FFE082;

  /* Orange */
  --orange100: #FFE0B2;
  --orange200: #FFCC80;

  /* Deep Orange */
  --deepOrange100: #FFCCBC;
  --deepOrange200: #FFAB91;

  /* Brown */
  --brown100: #D7CCC8;
  --brown200: #BCAAA4;

  /* Grey */
  --grey100: #F5F5F5;
  --grey200: #EEEEEE;

  /* Blue Grey */
  --blueGrey100: #CFD8DC;
  --blueGrey200: #B0BEC5;
  /* Red */
  --red800: #C62828;

  /* Pink */
  --pink800: #AD1457;

  /* Purple */
  --purple800: #6A1B9A;

  /* Deep Purple */
  --deepPurple800: #4527A0;

  /* Indigo */
  --indigo800: #283593;

  /* Blue */
  --blue800: #1565C0;

  /* Light Blue */
  --lightBlue800: #0277BD;

  /* Cyan */
  --cyan800: #00838F;

  /* Teal */
  --teal800: #00695C;

  /* Green */
  --green800: #2E7D32;

  /* Light Green */
  --lightGreen800: #558B2F;

  /* Lime */
  --lime800: #9E9D24;

  /* Yellow */
  --yellow800: #F9A825;

  /* Amber */
  --amber800: #FF8F00;

  /* Orange */
  --orange800: #EF6C00;

  /* Deep Orange */
  --deepOrange800: #D84315;

  /* Brown */
  --brown800: #4E342E;

  /* Grey */
  --grey800: #424242;

  /* Blue Grey */
  --blueGrey800: #37474F;

}

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--dark100);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--acc);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  color: white;
  background: var(--dark300);
  background: linear-gradient(148deg, var(--dark300) 0%, var(--dark400) 100%);
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  height: 100vh;
}

.hidden {
  display: none;
}

.block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.block.block-ver {
  flex-direction: column;
}

.block.block-hor {
  flex-direction: row;
  align-items: center;
}

.componentApp {
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
}

.component-page {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(400px, 450px) 1fr minmax(350px, 400px);
  height: calc(100vh - 80px);
  padding: 0;
  margin: 0;
  border: 0;
  gap: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  overflow: hidden;
}

.component-page.componentToDoPage {
  grid-template-columns: minmax(325px, 330px) 1fr;
}

.header {
  grid-column: 1 / -1;
  /* Span across all columns */
}

.block.componentHeader {
  height: 80px;
  margin-bottom: 0;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
}

.selected-item-header {
  display: none;
  padding-top: 5px;
  text-align: center;
}

.selected-item-header strong {
  font-weight: bold;
}

.block-logo {
  margin-bottom: 0;
  width: 200px;
  text-align: left;
}

.block-logo h1 {
  font-size: 24px;
  display: flex;
  align-items: center;
  line-height: 1;
}

.block-logo h1 strong {
  color: var(--acc);
  font-size: 36px;
  font-weight: bold;
}

.componentLoginForm .block-logo {
  margin-bottom: 10px;
}

.block-account {
  margin-bottom: 0;
  width: 200px;
  text-align: right;
  justify-content: flex-end;
}

nav {

  align-items: center;
  display: flex;
  justify-content: center;
}

nav ul {
  display: flex;
  gap: 40px;
}

nav a {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  text-decoration: none;
}

nav ul li a.active {
  color: var(--acc);
}

nav ul li a.active svg path {
  fill: var(--acc);
}


.mobile-navigation {
  display: none;
}

.main {
  display: grid;
  grid-column: 1 / -1;
  /* Optionally span across all columns if needed */
}

.section {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.componentLoginForm {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.componentLoginForm .card-form {
  max-width: 400px;
}

.component-list,
.component-main,
.component-settings,
.details-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  overflow: hidden;
}



h2 {
  font-weight: 500;
  font-size: 20px;
}

a {
  color: var(--white);
}

b,
strong {
  font-weight: bold;
}

.card {
  border: 1px solid var(--dark100);
  padding: 40px;
  border-radius: 40px;
  background: rgba(0 0 0 / .1);
}





.btn {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: fit-content;
  border: none;
  padding: 15px 20px 15px 20px;
  line-height: 1;
  border-radius: 20px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  cursor: pointer;
}

.btn svg {
  width: 15px;
  height: 15px;
}

.btn-round {
  padding: 0;
  min-width: 50px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.btn-round.btn svg {
  width: 20px;
  height: 20px;
}

.btn-acc {
  background-color: var(--acc);
  color: white;
}

.btn-secondary {
  background-color: var(--dark100);
  color: white;
}

.btn-success {
  background-color: #4CAF50;
  color: white;
}

.btn-error {
  background-color: #F44336;
  color: white;
}

.btn-acc:hover {
  color: var(--dark900);
}

.btn-secondary:hover {
  background-color: var(--acc);
}

.card-form {
  padding: 20px;
  border-radius: 20px;
}

.card-form-buttons {
  margin-bottom: 0;
}

.card-form-buttons>button:first-child {
  margin-right: 10px;
}

form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  /* Make sure it's not constrained */
}

.card-form-group {
  width: 100%;
}

.card-form-group h2 {
  padding-bottom: 15px;

}

.form-group {
  padding-bottom: 5px;
}

.form-group.w50 {
  flex: 0 0 calc(50% - 5px);
  /* Adjust for gap to ensure two items fit in a row */
  box-sizing: border-box;
  /* Include padding and border in width calculation */
}

.form-group.w100 {
  flex: 0 0 100%;
  box-sizing: border-box;
}


label {
  padding-bottom: 7px;
  display: block;
  font-size: 12px;
  padding-left: 7px;
}

.label {
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 12px;
}

.value {
  border-bottom: 1px solid var(--dark100);
  padding-bottom: 10px;
}

.value a {
  display: block;
  margin-top: 10px;
}

input,
select,
textarea,
.select__control.css-13cymwt-control,
.select__control.css-t3ipsp-control {
  background-color: var(--dark100);
  padding: 15px;
  width: 100%;
  color: var(--white);
  border-radius: 25px;
  border: 0;
  padding-left: 40px;
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: left 15px center;
  box-sizing: border-box;
}

.radio-group {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
}

.radio-group-type{
  flex-wrap: wrap;
}

.radio-group input[type="radio"] {
  display: none;
}

.radio-group label {
  padding: 10px 20px;
  border-radius: 20px;
  border: 2px solid #ccc;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
}
.radio-group .radio-label-email{
  border-color: var(--pink800);
}
.radio-group .radio-label-phone{
  border-color: var(--blue800);
}
.radio-group .radio-label-meeting{
  border-color: var(--green800);
}
.radio-group .radio-label-note{
  border-color: var(--yellow800);
}
.radio-group .radio-label-other{
  border-color: var(--grey800);
}
.radio-group .radio-label-system{
  border-color: var(--brown800);
}
.radio-label-email.active{
  background-color: var(--pink800);
}
.radio-label-phone.active{
  background-color: var(--blue800);
}
.radio-label-meeting.active{
  background-color: var(--green800);
}
.radio-label-note.active{
  background-color: var(--yellow800);
}
.radio-label-other.active{
  background-color: var(--grey800);
}
.radio-label-system.active{
  background-color: var(--brown800);
}

.radio-group input[type="radio"]:checked+label {
  background-color: var(--acc);
  color: white;
  border-color: var(--acc);
}

.radio-group label:hover {
  border-color: white;
}

.select__menu {
  background-color: var(--dark900) !important;
  border-radius: 20px !important;
  overflow: hidden !important;
}

.select__input-container,
.select__single-value {
  color: white !important;
}

.Select__control {
  background-color: white;
  /* Change according to your theme */
  border: 1px solid #cccccc;
  /* Custom border color */
  box-shadow: none;
  /* Removing box-shadow if not needed */
  height: 40px;
  /* Adjust the height as needed */
}

.Select__control--is-focused {
  border-color: #666666;
  /* Change focus border color */
  box-shadow: none;
  /* Removing box-shadow on focus if not needed */
}

.Select__option--is-selected {
  background-color: #f0f0f0;
  /* Background of selected option */
}

.Select__option--is-focused {
  background-color: #e0e0e0;
  /* Background of option on hover or focus */
}

input[name='firstname'],
input[name='lastname'],
input[name='name'] {
  background-image: url(icons/person.svg);
}

input[name='email'] {
  background-image: url(icons/email.svg);
}

input[name='phone'] {
  background-image: url(icons/phone.svg);
}

input[name='password'] {
  background-image: url(icons/password.svg);
}

input[name='assignedManager'],
select[name='assignedManager'] {
  background-image: url(icons/manager.svg);
}

input[name='managers-search'],
input[name='clients-search'],
input[name='managers-search'] {
  background-image: url(icons/search.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: left 20px center;
  padding-left: 50px;
}

select[name='type'] {
  background-image: url(icons/communication.svg);
}

select[name='manager'] {
  background-image: url(icons/person.svg);
}

input[name='date'] {
  background-image: url(icons/calendar.svg);
}
input[name='time'] {
  background-image: url(icons/time.svg);
}

input[name='projectName'] {
  background-image: url(icons/project.svg);
}

select[name='projectStatus'] {
  background-image: url(icons/projectstatus.svg);
}

textarea[name='details'],
input[name='companyName'] {
  background-image: url(icons/note.svg);
}

input[name='basicPrice'] {
  background-image: url(icons/payment1.svg);
}

input[name='monthPrice'] {
  background-image: url(icons/payment30.svg);
}


textarea {
  min-height: 100px;
}

form button {
  margin-left: auto;
  margin-top: 10px;
}


/* General Table Styling */
table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
  border: none;
}

/* Table Headers */
th {
  font-size: 12px;
  text-align: left;
  padding: 10px 10px;
  padding-left: 0;
  background-color: var(--dark300);
}

tr th:last-child {
  text-align: right;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

tr th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 10px;
}

th svg {
  margin-bottom: 5px;
}

tr:last-child td {
  border-bottom: none;
  padding-bottom: 0;
}

tr:hover {
  background-color: var(--dark400);
}

/* Table Cells */
td {
  padding: 15px 0;
  border-bottom: 1px solid var(--dark100);
  font-size: 18px;
}

tr td:last-child {
  text-align: right;
}



/* Responsive adjustments */
@media (max-width: 600px) {

  th,
  td {
    padding: 12px 16px;
  }
}



.card-ManagersList,
.card-ClientsList,
.card-LeadsList,
.card-ProjectsList {
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0;
  overflow: hidden;
  margin-bottom: 0;
}

#block-managers-list,
#block-clients-list,
#block-leads-list,
#block-projects-list,
.componentProjectNew {
  flex-grow: 1;
  justify-content: flex-start;
  overflow: auto;
  padding-right: 10px;
}

.componentProjectNew {
  min-height: 575px;
  margin-bottom: 25px;
}


.block-manager-item,
.block-customer-item {
  background-color: var(--dark600);
  padding: 20px 30px;
  border-radius: 40px;
  margin-bottom: 10px;
  border: 1px solid var(--dark100);
}

.block-list-item-active {
  border: 1px solid var(--white);
}

.block-item-title {
  margin-bottom: 0;
}

.block-item-title a {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  text-decoration: none;
}

.block-count,
.block-stats {
  display: flex;
  justify-content: space-between;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 10px;
  font-size: 10px;
  border: 1px solid var(--dark100);
  line-height: 1;
}

.block-stats {
  min-width: 100%;
  justify-content: space-around;
  box-sizing: border-box;
  line-height: 1.6;
  font-size: 10px;
}

.block-count .count-item,
.block-stats .stats-item {
  padding-top: 5px;
  padding-bottom: 5px;
  min-width: 55px;
}

.block-count .count-overdue,
.block-stats .count-overdue {
  color: #F44336;
}

.block-project-item .block-count .count-item b {
  font-size: 12px;
  display: block;
  padding: 5px 0;
  line-height: 1.2;
}

.count-days-since.red b {
  color: var(--red800);
}

.count-days-since.yellow b {
  color: var(--yellow800);
}

.count-days-since.green b {
  color: var(--green800);
}

.count-days-since.no-activity b {
  color: var(--dark100);
}


.block-count> :nth-child(n+2),
.block-stats> :nth-child(n+2) {
  border-left: 1px solid var(--dark100);
  padding-left: 7px;
  margin-left: 7px;
}

.block-customer-projects {
  margin-bottom: 0;
}

.block-customer-project {
  display: block;
  text-align: center;
  border: 1px solid white;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--dark200);
  border-color: var(--dark100);
}

.block-customer-project a {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.block-contacts {
  margin-bottom: 0;
}

.block-contacts .contacts-item {
  margin-left: 10px;
}

.card.componentManagerNew {
  padding: 40px 20px;
  background-color: var(--dark300);
  margin-bottom: 40px;
}

.componentManagerNew h2 {
  padding-bottom: 40px;
}

#block-ClientsLeads-header {
  padding-left: 10px;
  min-height: 50px;
}

#card-Clients,
#card-Leads {
  background-color: var(--dark500);
}

.componentCustomerNew {
  margin-bottom: 40px;
}



.block-details-buttons {
  margin-bottom: 0;
}

.block-details-buttons .btn {
  margin-left: 10px;
}

.componentInteractionForm {
  margin-bottom: 40px;
}

#card-Interactions {
  background-color: var(--dark500);
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 40px 0;
  padding-right: 20px;

}

#card-Interactions .card-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 40px;
  padding-right: 20px;
}

.interactions-list {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--dark100);

}

#card-Sites {
  padding-left: 40px;
  height: 100%;
  margin: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}

.componentSiteForm {
  margin-bottom: 20px;
}

.interactions-list:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-50%, 50%);
  width: 5px;
  height: 5px;
  background-color: var(--white);
  border-radius: 50%;
}

.interactions-list.interactions-list-planned {
  border-left: 1px solid var(--blue800);
}

.interactions-list.interactions-list-overdue {
  border-left: 1px solid rgb(255 71 71);
}

.interactions-list.interactions-list-complete {
  border-left: 1px solid rgb(111 111 111);
  padding-bottom: 0;
  border-bottom: none;
}

.interactions-list.interactions-list-complete:after {
  display: none;
}

.interactions-list h3 {
  text-align: center;
  padding-bottom: 40px;
}

.block.interactions-item,
.componentSiteItem,
.site-item {
  list-style: none;
  align-items: flex-start !important;
  margin-bottom: 40px;
}

.site-item {
  border-bottom: 1px solid var(--dark100);
  padding-bottom: 20px;
}

.interactions-list-overdue .block.interactions-item {
  border: 1px solid var(--dark100);
  padding: 40px;
  border-radius: 40px;
  background: var(--dark400);
  padding-left: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-color: rgb(255 71 71);
  border-left: 0;
}

.interactions-list ul li:last-child .interactions-item {
  margin-bottom: 0;
}

.interactions-item-img,
.site-item-img {
  position: relative;
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  background-color: var(--white);
  border-radius: 50%;
  transform: translateX(-50%);
  background-position: center;
  background-repeat: no-repeat;
  font-size: 0;
  background-size: 25px;
}

.interactions-item-text,
.site-item-text,
.site-item-header {
  flex: 1;
  margin-bottom: 0;
}

.interactions-item-text span {
  text-transform: capitalize;
  font-weight: 600;
}

.interactions-item-1row {
  margin-bottom: 0;
  min-height: 50px;
}

.interactions-item-title,
.site-item-title {
  margin-bottom: 0;
}

.interactions-item-buttons,
.site-item-buttons {
  margin-bottom: 0;
}

.interactions-item-buttons .btn,
.site-item-buttons .btn {
  margin-left: 10px;
}

.interactions-item-date,
.site-item-status {
  font-size: 12px;
  padding-top: 5px;
}

.interactions-item-details,
.site-item-details {
  display: block;
  background-color: var(--dark100);
  padding: 20px;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 0;
  white-space: pre-wrap;
}


.interactions-item-question {
  margin-top: 20px;
  margin-bottom: 0;
}

.interactions-item-question p {
  font-style: italic;
  display: block;
  margin-bottom: 10px;
}

.interactions-item-question-buttons {
  justify-content: flex-start;
  margin-bottom: 0;
}

.interactions-item-question-buttons .btn-error {
  margin-right: 10px;
}

.block-project-item {
  padding: 15px;
  border: 1px solid var(--dark100);
  border-radius: 10px;
  background-color: var(--dark600);
  cursor: pointer;
}

.block-project-item:hover {
  border-color: white;
}

.block-project-item.active {
  border-color: var(--acc);
}

.block-project-status {
  text-align: right;
  font-size: 12px;
  margin-bottom: 40px;
}

.block-project-item .item-name a {
  display: block;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
}

.block-project-item .item-customer a {
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  text-underline-offset: 3px;
}

p.item-customer {
  display: flex;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 7px 15px;
  border-radius: 10px;
  border: 1px solid var(--dark100);
  line-height: 1;
  background-color: var(--dark200);
  margin-top: 5px;
  align-items: center;
  gap: 10px;
}

p.item-customer svg {
  width: 18px;
  height: 18px;
}


.block-project-sites {
  margin-top: 10px;
}

.block-project-site {
  margin-bottom: 10px;
  border: 1px solid rgba(255 255 255 / .1);
  padding: 10px;
  border-radius: 10px;
  background-color: var(--dark100);
}

.block-project-item .item-site-status {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
}

.componentToDoPage {}

.card-todo-title h1 {
  font-size: 24px;
  text-wrap: nowrap;
  padding-right: 30px;
  font-weight: 500;
}

.card-todo-title input {
  cursor: pointer;
}

.block-todo-list {
  flex-grow: 1;
  justify-content: flex-start;
  overflow: auto;
  padding-right: 10px;
}

.project-group {
  margin-bottom: 30px;
}

.project-group .project-group-content {
  align-items: flex-start;
}

.project-group .project-title {
  min-width: 300px;
  padding: 15px;
}

.project-group .project-title h2 {
  margin-bottom: 30px;
}

.project-group .project-interactions {
  width: 100%;
  background-color: var(--dark600);
}

#block-projects-filters {
  display: flex;
  border-radius: 10px;
  border: 1px solid var(--dark100);
  line-height: 1;
  padding: 3px;
}

#block-projects-filters label {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  padding-bottom: 0;
  padding: 7px;
  border-radius: 10px;
}

#block-projects-filters label:hover {
  background-color: var(--dark100);
  cursor: pointer;
}

#block-projects-filters label:hover input {
  cursor: pointer;
}

.componentClientInfo {
  margin-top: 30px;
  background-color: var(--dark200);
}

.componentToDoInteractionsList .card-content {
  flex-grow: 1;
  justify-content: flex-start;
  overflow: auto;
  padding-right: 10px;
  padding-left: 30px;
}



.project-group .interactions-list.interactions-list-complete {
  opacity: .5;
}

.block-nav-categories .btn {
  max-width: none;
  width: 100%;
  justify-content: center;
  margin: 0 15px;
  margin-bottom: 15px;
}

.block-nav-categories .btn.active {
  background-color: var(--acc);
}

.card-todo-customer {
  padding: 8px 8px;
  border-radius: 50px;
  padding-left: 20px;
  margin-top: 0;
  margin-bottom: 0;
  background-color: var(--dark100);
}

.card-todo-customer-content {
  margin-bottom: 0;
}

.block-customer-title {
  padding-right: 50px;
}

.card-todo-customer-content a {
  padding-bottom: 0;
}

.card-todo-customer-content a.btn-round {
  background-color: var(--dark400);
}

.card-todo-customer-content .block-customer-title a {
  text-decoration: underline;
  text-underline-offset: 3px;
}

.componentToDoInteraction .block-buttons {
  padding: 8px 8px;
  border-radius: 50px;
  margin-top: 15px;
}

.componentToDoInteraction .block-buttons .btn-secondary {
  background-color: var(--dark400);
  margin-right: 15px;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.componentToDoInteraction .block-buttons .btn-secondary.expanded {
  color: var(--acc);
}

.componentToDoInteraction .block-buttons .btn-error {
  margin-left: auto;
}

.componentToDoInteraction .block-buttons .btn-success {
  margin-left: 15px;
}

.interactions-list-overdue .componentLast3Interactions .block.interactions-item {
  border-color: var(--dark100);
}


.componentLast3Interactions .card-content {
  overflow: visible;
}

.componentLast3Interactions .interactions-list {
  border-left: 1px solid rgba(255 255 255 / .3);
  border-bottom: 0;
  padding-bottom: 15px;
  padding-top: 15px;
}

.interactions-project-details {
  align-items: flex-start;
}

.interactions-project-details h3 {
  padding-bottom: 30px;
  font-size: 18px;
  font-weight: 500;
}

.interactions-project-details-title {
  margin-right: auto;
}

.interactions-project-details-infoblock {
  background-color: var(--dark200);
  border-radius: 15px;
  padding: 15px;
  margin-left: 30px;
  align-items: flex-start;
  padding-right: 20px;
  margin-bottom: 0;
}

.interactions-project-details-infoblock h3 {
  padding-top: 30px;
  padding-bottom: 0;
}

.air-datepicker.-inline- {
  background-color: var(--dark600);
  color: white;
}

.air-datepicker-body--day-name {
  color: var(--acc) !important;
}

.air-datepicker-cell.-disabled- {
  color: rgba(255 255 255 / .2) !important;
}

.air-datepicker-cell.-current- {
  background-color: transparent;
  color: white !important;
  border: 1px solid white;
}
.air-datepicker-cell.-focus-{
  background-color: transparent !important;
  border: 1px solid var(--acc) !important;
}

.air-datepicker-cell.-selected- {
  color: #fff !important;
  background: var(--acc) !important;
}

.air-datepicker-time--current-hours:after, .air-datepicker-time--current-minutes:after{
  background-color: var(--acc) !important;
}


.componentFloatingShapes {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.shapeAnim1 svg {
  animation: shapeAnim1 25s infinite alternate;
}

.shapeAnim2 svg {
  animation: shapeAnim2 25s infinite alternate;
}

.shapeAnim3 svg {
  animation: shapeAnim3 25s infinite alternate;
}

.shapeAnim4 svg {
  animation: shapeAnim4 25s infinite alternate;
}

.shapeAnim5 svg {
  animation: shapeAnim5 25s infinite alternate;
}

.shapeAnim6 svg {
  animation: shapeAnim6 25s infinite alternate;
}

.shapeAnim7 svg {
  animation: shapeAnim7 25s infinite alternate;
}

.shapeAnim8 svg {
  animation: shapeAnim8 25s infinite alternate;
}

.shapeAnim9 svg {
  animation: shapeAnim9 25s infinite alternate;
}

.shapeAnim10 svg {
  animation: shapeAnim10 25s infinite alternate;
}

@keyframes shapeAnim1 {

  0%,
  100% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(10px, 20px);
  }
}

@keyframes shapeAnim2 {

  0%,
  100% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(-10px, 15px);
  }
}

@keyframes shapeAnim3 {

  0%,
  100% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(15px, -10px);
  }
}

@keyframes shapeAnim4 {

  0%,
  100% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(-15px, 10px);
  }
}

@keyframes shapeAnim5 {

  0%,
  100% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(20px, -20px);
  }
}

@keyframes shapeAnim6 {

  0%,
  100% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(-20px, -15px);
  }
}

@keyframes shapeAnim7 {

  0%,
  100% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(10px, -25px);
  }
}

@keyframes shapeAnim8 {

  0%,
  100% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(-10px, 25px);
  }
}

@keyframes shapeAnim9 {

  0%,
  100% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(25px, 15px);
  }
}

@keyframes shapeAnim10 {

  0%,
  100% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(-25px, -10px);
  }
}


@media (max-width: 1600px) {

  h2 {
    font-size: 18px;
  }

  .value {
    font-size: 16px;
  }

  .component-page {
    grid-template-columns: minmax(350px, 400px) 1fr minmax(350px, 400px);
  }

  .block.componentHeader {
    padding-left: 30px;
    padding-right: 30px;
  }

  .card,
  .block-manager-item,
  .block-customer-item {
    padding: 20px;
    border-radius: 20px;
  }

  .block-customer-item {
    padding-bottom: 0;
  }

  .interactions-list-overdue .block.interactions-item {
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 0;
  }

  #card-Interactions {
    padding: 30px 0;
  }

  .interactions-list {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .interactions-list h3 {
    padding-bottom: 30px;
  }

  .interactions-list-overdue .block.interactions-item {
    border-radius: 30px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  td {
    font-size: 16px;
  }

  .card.componentManagerNew {
    padding: 20px;
    margin-bottom: 20px;
  }

  .componentManagerNew h2 {
    padding-bottom: 20px;
  }

  .block-count .count-overdue,
  .block-stats .count-overdue {
    font-weight: 600;
  }

}

@media (max-width: 1400px) {

  .block-manager-item,
  .block-customer-item {
    padding: 15px;
    border-radius: 20px;
    margin-right: 15px;
  }

  .component-page {
    gap: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    grid-template-columns: minmax(300px, 350px) 1fr minmax(300px, 350px);
  }

  #block-ClientsLeads-header {
    min-height: 40px;
  }

  th svg {
    display: none;
  }

  td {
    font-size: 14px;
  }

  .label {
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 10px;
  }

  .value {
    font-size: 14px;
  }

  .btn {
    padding: 10px 15px 10px 10px;
    font-size: 12px;
  }

  .btn-round {
    min-width: 40px;
    min-height: 40px;
    padding: 0;
  }

  input,
  select,
  textarea,
  .select__control.css-13cymwt-control,
  .select__control.css-t3ipsp-control {
    padding: 10px;
    border-radius: 20px;
    padding-left: 30px;
    background-position: left 10px center;
  }


  #card-Clients,
  #card-Leads {
    flex-grow: 1;
    overflow: auto;
  }

  .componentManagerContacts div .label:first-child {
    padding-top: 0;
  }

  .componentInteractionForm,
  .componentCustomerNew {
    margin-bottom: 20px;
  }

  .block.componentHeader {
    padding-left: 20px;
    padding-right: 20px;
  }

  .block-customer-item {
    padding-bottom: 0;
  }

  .interactions-item-img {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    background-size: 20px;
  }

  #block-managers-list,
  #block-clients-list,
  #block-leads-list {
    margin-bottom: 0;
  }

  .interactions-list-overdue .block.interactions-item {
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .block.interactions-item .card-form {
    padding: 10px;
  }

  #card-Interactions {
    padding: 20px 10px 20px 0;
  }

  #card-Interactions .card-content {
    padding: 0 30px;
  }
}

@media (max-width: 1200px) {

  .block.componentHeader {
    height: 70px;
  }

  .block-logo h1 {
    font-size: 18px;
  }

  .block-logo h1 strong {
    font-size: 24px;
  }

  .componentHeader .btn {
    font-size: 0;
    gap: 0;
    padding: 15px;
  }

  .selected-item-header {
    display: block;
  }

  nav ul {
    gap: 20px;
  }


  .mobile-navigation {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    border-radius: 30px;
    background-color: var(--dark100);
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .mobile-navigation::before {
    content: '';
    display: block;
    width: 30%;
    top: 10px;
    bottom: 10px;
    transform: translateX(-50%);
    background-color: var(--dark800);
    position: absolute;
    border-radius: 20px;
    z-index: 1;
    transition: .6s;
  }

  .mobile-navigation.list::before {
    left: 19%;
  }

  .mobile-navigation.content::before {
    left: 50%;
  }

  .mobile-navigation.details::before {
    left: 81%;
  }

  .mobile-navigation .btn {
    position: relative;
    background-color: transparent;
    color: white;
    width: 33%;
    max-width: none;
    justify-content: center;
    padding: 10px;
    z-index: 2;
  }

  button:disabled,
  button[disabled] {
    opacity: .5;
  }

  .component-page {
    display: flex;
    width: 300vw;
    min-width: 300vw;
    max-width: 300vw;
    height: calc(100vh - 155px);
    gap: 0;
    padding: 0;
  }

  .component-page .section {
    transition: .6s;
    width: 100vw;
    padding: 10px;
  }

  .component-page.list .section-content {
    transform: perspective(70vw) rotateY(-50deg) scale(1.2);
    opacity: 0;
  }

  .component-page.list .section-details {
    transform: perspective(70vw) rotateY(-50deg) scale(1.2);
    opacity: 0;
  }

  .component-page.content .section-list {
    transform: translate(-100%) perspective(70vw) rotateY(50deg) scale(1.2);
    opacity: 0;
  }

  .component-page.content .section-content {
    transform: translate(-100%);
    opacity: 1;
  }

  .component-page.content .section-details {
    transform: translate(-100%) perspective(70vw) rotateY(-50deg) scale(1.2);
    opacity: 0;
  }

  .component-page.details .section-list {
    transform: translate(-200%) perspective(70vw) rotateY(50deg) scale(1.2);
    opacity: 0;
  }

  .component-page.details .section-content {
    transform: translate(-200%) perspective(70vw) rotateY(50deg) scale(1.2);
    opacity: 0;
  }

  .component-page.details .section-details {
    transform: translate(-200%);
    opacity: 1;
  }


  #block-ClientsLeads-header {
    min-height: auto;
  }

  table thead tr {
    display: none;
    /* Hide table headers on small screens */
  }

  table,
  table tbody,
  table tr,
  table td {
    display: block;
    /* Block display to stack the table content */
    width: 100%;
  }

  table td {
    position: relative;
    padding-left: 40%;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  table td::before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    width: 35%;
    padding-right: 0;
    white-space: nowrap;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
  }

  table tr {
    border-bottom: 1px solid var(--dark100);
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  tr td:last-child {
    text-align: left;
  }

  table td a {
    color: var(--acc);
    text-decoration: none;
    font-weight: bold;
  }

  tbody tr:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }

  #card-Interactions .card-content {
    padding: 0 20px;
  }



  .interactions-list-overdue .block.interactions-item {
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }

  .interactions-item-details {
    margin-top: 10px;
  }

  .componentLoginForm {
    padding-left: 15px;
    padding-right: 15px;
  }




}